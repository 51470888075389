import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, ConfigProvider, InputNumber, Radio, Space, notification, Checkbox, DatePicker, Table, Col, Row, Card, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import ResultParte1 from './components/ResultParte1'
import ResultParte2 from './components/ResultParte2'
import changeHeight from '../../data/changeHeight';
import FormularioParte2 from "./components/FormularioParte2";
import FormularioParte3 from './components/FormularioParte3';
import axios from 'axios';
import ResultPaginaPrincipalParte3 from './components/ResultParte3';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

const dateFormat = 'DD/MM/YYYY';

const Fintonic = () => {
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const [value, setValue] = useState("");
    const [datosForm, setDatosForm] = useState({});
    const [paso, setPaso] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [NotEnsureFields, setNotEnsureFields] = useState(false);
    const [priceBasic, setPriceBasic] = useState(null);
    const [pricePremium, setPricePremium] = useState(null);
    const [isBanco, setIsBanco] = useState(true);
    //Cálculo edad

    const calculateAge = (birthdate) => {
        if (!birthdate) return null;
        return dayjs().diff(dayjs(birthdate), 'years');
    };

    const handleBirthdayChange = (date) => {
        form.setFieldsValue({ capital_to_ensure: null });
    };

    const disabledDateBefore18YearsAgo = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'years');
        return current && current > eighteenYearsAgo;
    };


    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
        }
        if (searchParams.get("parentUrl")) {
            const parentUrl = decodeURIComponent(searchParams.get("parentUrl"));
            form.setFieldsValue({ url: parentUrl });
            
            // Crear un objeto URL para parsear los parámetros de parentUrl
            try {
                const urlObj = new URL(parentUrl);
                const parentParams = new URLSearchParams(urlObj.search);
                
                // Comprobar y establecer email
                if (parentParams.get("email")) {
                    form.setFieldsValue({ email: parentParams.get("email") });
                }
                
                // Comprobar y establecer teléfono
                if (parentParams.get("phone")) {
                    form.setFieldsValue({ tel: parentParams.get("phone") });
                }
                
                // Comprobar y establecer importe
                if (parentParams.get("origin_price")) {
                    form.setFieldsValue({ importe: parseFloat(parentParams.get("origin_price")) });
                }
            } catch (error) {
                console.log("Error al parsear parentUrl:", error);
            }
        }
        
        // Si en la url se incluye /landing/caser_*/<aqui estara el nombre del agente> se rellenará el campo nombre_agente
        const url = decodeURIComponent(searchParams.get("parentUrl"));
        console.log('url', url);
        if (url && url.includes("caser_")) {
            const urlParts = url.split("/");
            const agentName = urlParts[urlParts.length - 1];
            form.setFieldsValue({ nombre_agente: agentName });
            console.log(agentName);
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = (values) => {
        setbuttonLoading(true);

        const headers = {
            "Content-Type": "application/json"
        }

        if (values.procedencia === 'Aseguradora' && values.importe < 500) {
            setbuttonLoading(false);
            api.info({
                message: 'Error',
                description: 'El importe mínimo en caso de que tu seguro venga de una aseguradora es de 150€',
                placement: 'bottom',
                icon: (
                    <CloseCircleOutlined style={{ color: "#f7151c" }} />
                )
            });
            return;
        }

        axios.post(process.env.REACT_APP_URL_CALCULO_RAPIDO, values, {
            headers: headers
        })
            .then(function (response) {
                if (response.data.message === "OK") {
                    setDatosForm(values);
                    if (values.procedencia === 'NoSeguro') {
                        setPriceBasic(response.data.priceData.priceBasic);
                        setPricePremium(response.data.priceData.pricePremium);
                    } else {
                        setPriceBasic(null);
                        setPricePremium(null);
                    }

                    setbuttonLoading(false);
                    setSuccess(true);
                    setPaso(1);
                    console.log(values);
                }
            })
            .catch(function () {
                openNotification('bottom')
                setbuttonLoading(false);
            });
    };

    const onChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'NoSeguro' || e.target.value === 'Aseguradora') {
            setIsBanco(false);
        } else setIsBanco(true);
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const customParser = (value) => {
        // Reemplaza comas por puntos
        const processedValue = value.replace(/,/g, '.');
      
        // Analiza el valor en un número de punto flotante
        const parsedValue = parseFloat(processedValue);
      
        // Asegúrate de que el resultado es un número
        if (!isNaN(parsedValue)) {
          return parsedValue;
        }
      
        return undefined; // Si no se pudo analizar, devuelve undefined
      };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                locale={esES}
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Form
                        form={form}
                        style={{ padding: '1.5rem' }}
                        hidden={success}
                        name="basic"
                        initialValues={{ remember: true }}
                        layout='vertical'
                        onFinish={onFinish}
                        requiredMark={false}
                    >
                        <Form.Item
                            hidden={true}
                            label="URL"
                            name="url"
                        >
                            <Input type='text' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="nombre_agente"
                            name="nombre_agente"
                        >
                            <Input type='text' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="FB"
                            name="fbp">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="Hubspotutk"
                            name="hubspotutk">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="userAgent"
                            name="userAgent">
                            <Input type='' />
                        </Form.Item>
                        {!NotEnsureFields && (
                            <Form.Item
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuánto pagas al año por tu seguro de vida? (€) *</p>}
                                name="importe"
                                style={{ marginBottom: "0.6rem" }}
                                colon={false}
                                rules={[{ required: true, message: 'Por favor, ingresa el importe de tu seguro' }]}

                                
                            >
                                {/* Se debe permitir tanto comas como puntos como separador decimal */}
                                <InputNumber style={{ width: '100%' }} placeholder='700' disabled={buttonLoading} parser={customParser} />
                            </Form.Item>
                        )}
                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Con quién tienes la póliza contratada actualmente? *</p>}
                            name="procedencia"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Indique la procedencia del seguro' }]}
                            initialValue="Banco"
                        >
                            <Radio.Group onChange={onChange} value={value}>
                                <Space
                                    direction='vertical'
                                    style={{ marginLeft: '1rem' }}
                                >
                                    <Radio value={"Banco"}>Banco</Radio>
                                    {/*
                                    <Radio value={"Aseguradora"}>Aseguradora</Radio>
                                    <Radio value={"NoSeguro"}>No tengo seguro a día de hoy</Radio>
                                    */}
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        {/* Este mensaje se debe ver si el usuario ha seleccionado la opción "No tengo seguro a día de hoy" o "Aseguradora" */}
                        <div style={{ display: `${isBanco ? 'none' : 'block'}` }}>
                            <Alert style={{marginTop: "15px", marginBottom: "15px", paddingBottom: "15px"}} message={<><p>Esta modalidad no está disponible actualmente a través de este formulario, te recomendamos calcular el precio a través de esta web</p> <Button color='default' variant="filled"  href="https://calcular-seguro-vida.caser.es/caser/seguro-vida" target="_blank">Ir a Caser Vida</Button></>} type="info" />
                        </div>

                        {NotEnsureFields && (
                            <>
                                <Form.Item
                                    label="Fecha de nacimiento"
                                    name="birthday"
                                    rules={[{ required: true, message: 'Ingresa su fecha de nacimiento' }]}
                                >
                                    <DatePicker defaultPickerValue={dayjs().subtract(18, 'years')} disabledDate={disabledDateBefore18YearsAgo} style={{ width: '100%' }} format={dateFormat} onChange={handleBirthdayChange} showToday={false} />
                                </Form.Item>

                                <Form.Item
                                    label="Cantidad que quieres asegurar"
                                    name="capital_to_ensure"
                                    initialValue={50000}
                                    rules={[
                                        { required: true, message: 'Ingresa la cantidad que quieres asegurar' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const age = calculateAge(getFieldValue('birthday'));

                                                if (age >= 18 && age <= 59) {
                                                    if (value < 50000 || value > 500000) {
                                                        return Promise.reject(new Error('La cantidad debe ser entre 50.000 y 500.000'));
                                                    }
                                                } else if (age >= 60 && age <= 74) {
                                                    if (value < 10000 || value > 50000) {
                                                        return Promise.reject(new Error('La cantidad debe ser entre 10.000 y 50.000'));
                                                    }
                                                } else {
                                                    // Edad mayor de 74 años, bloquea el campo
                                                    return Promise.reject(new Error('Los mayores a 74 años no pueden realizar un seguro'));
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te llamamos? *</p>}
                            name="tel"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                        >
                            <Input maxLength={9} minLength={9} placeholder='619 99 31 31' type='tel' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar el email? *</p>}
                            name="email"
                            style={{ marginBottom: "0.6rem" }}
                            rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                        >
                            <Input placeholder='tunombre@gmail.com' type='email' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                            <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem", width: '100%', marginTop: '1rem' }}
                            disabled={!isBanco}
                        >
                            <Button disabled={!isBanco} type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }}>
                                {buttonLoading ? 'Enviando...' : 'Ver mi precio'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                <div style={{ display: `${paso === 1 ? 'block' : 'none'}` }}>
                    <ResultParte1 datos={datosForm} priceBasic={priceBasic} pricePremium={pricePremium} onClick={() => setPaso(2)} />
                </div>

                <div style={{ display: `${paso === 2 ? 'block' : 'none'}` }}>
                    <FormularioParte2 datos={datosForm} setDatosForm={setDatosForm} onClick={() => { setPaso(3); }} />
                </div>

                <div style={{ display: `${paso === 3 ? 'block' : 'none'}` }}>
                    <ResultParte2 datos={datosForm} onClick={() => { setPaso(4); }} />
                </div>

                <div style={{ display: `${paso === 4 ? 'block' : 'none'}` }}>
                    <FormularioParte3 datos={datosForm} onClick={() => { setPaso(5); }} />
                </div>

                <div style={{ display: `${paso === 5 ? 'block' : 'none'}` }}>
                    <ResultPaginaPrincipalParte3 datos={datosForm} />
                </div>
            </ConfigProvider>

        </>
    );
}

export default Fintonic;