import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, ConfigProvider, Radio, Result, notification, Card, Row, Col, Alert, Checkbox } from 'antd';
import 'dayjs/locale/es';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Link, useSearchParams } from 'react-router-dom';
import changeHeight from '../../data/changeHeight';
import axios from 'axios';



const SegundaOportunidad = () => {
    const [step, setStep] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [userData, setUserData] = useState({});
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [resultError, setResultError] = useState("none");
    const [result, setResult] = useState("none");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [alertDeudas, setAlertDeudas] = useState(false);

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setHeightResult(refResult.current.clientHeight);
            changeHeight(refForm.current.clientHeight);
            setResultError("none");
            setResult("none");
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: searchParams.get("parentUrl") });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = (values) => {
        setButtonLoading(true);
        setUserData(values);

        if (values.paymentStatus === true) {
            axios.post(`${process.env.REACT_APP_URL_PRIMA_UNICA}`, values)
                .then(function (response) {
                    if (response.data.message === "OK") {
                        setButtonLoading(false);
                        setStep(1);
                        setResultError("block");
                        changeHeight(heightResult);
                    }
                })
                .catch(function () {
                    openNotification('bottom');
                    setButtonLoading(false);
                });
        } else {
            setButtonLoading(true);
            console.log('VALORES:', values);
            axios.post(`${process.env.REACT_APP_URL_PRIMA_UNICA}`, values)
                .then(function (response) {
                    if (response.data.message === "OK") {
                        setButtonLoading(false);
                        setSuccess(true);
                        setResult("block");
                        setStep(2);
                        changeHeight(heightResult);
                    }
                })
                .catch(function () {
                    openNotification('bottom');
                    setButtonLoading(false);
                });
        }
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const subtitleText = "Tu información ha sido enviada correctamente.<br/>En breve nos pondremos en contacto contigo, pero recuerda que puedes concertar una cita haciendo clic en el siguiente botón.";

    const onChangeDeudas = (value) => {
        if (value === false) {
            setAlertDeudas(true);
        } else {
            setAlertDeudas(false);
        }
    };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>

                <div ref={refForm} style={step === 0 ? { display: 'block' } : { display: 'none' }}>
                    <Form
                        form={form}
                        style={{ padding: '1rem' }}
                        hidden={success}
                        name="basic"
                        initialValues={{ remember: true }}
                        layout='vertical'
                        onFinish={onFinish}
                    >
                        {/* Form items */}
                        <Form.Item
                            hidden={true}
                            label="URL"
                            name="url"
                        >
                            <Input type='text' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="FB"
                            name="fbp">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="Hubspotutk"
                            name="hubspotutk">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Debes dinero a dos o más entidades (bancos, financieras...)?</p>}
                            name="deudas"
                            rules={[{ required: true, message: 'Por favor, selecciona una opción' }]}
                        >
                            <Radio.Group 
                                style={{ paddingLeft: "10px" }}
                                disabled={buttonLoading} 
                                onChange={(e) => onChangeDeudas(e.target.value)}
                            >
                                <Radio value={true}>Sí</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <div style={{ display: `${alertDeudas ? "block" : "none"}` }}>
                            <Alert
                                style={{ marginTop: "3%", marginBottom: "3%" }}
                                message="¡Lamentamos mucho informarte que, en este momento, no cumples con los requisitos necesarios para acogerte a la Ley de Segunda Oportunidad. Uno de los requisitos indispensables es contar con dos o más deudas con diferentes acreedores, por lo que no reúnes las condiciones mínimas para beneficiarte de esta ley."
                                type="error"
                            />
                        </div>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Estas al día del pago de todas tus deudas?</p>}
                            name="paymentStatus"
                            rules={[{ required: true, message: 'Por favor selecciona una opción' }]}
                        >
                            <Radio.Group style={{ paddingLeft: "10px" }}>
                                <Radio value={true}>Sí</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar un email?</p>}
                            name="email"
                            rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                        >
                            <Input placeholder='tunombre@email.com' type='email' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde podemos llamarte?</p>}
                            name="tel"
                            rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                        >
                            <Input placeholder='919 99 31 31' type='tel' disabled={buttonLoading} maxLength={9} minLength={9} />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0rem" }}
                            valuePropName="checked"
                            name="terms"
                            initialValue={false}
                            rules={[{
                                required: true,
                                validator: async (_, checked) => {
                                    if (!checked) {
                                        return Promise.reject(new Error("Por favor, acepta los términos y condiciones"));
                                    }
                                }
                            }]}
                        >
                            <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading}>
                                Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link>
                            </Checkbox>
                        </Form.Item>

                        <Button
                            type='primary'
                            loading={buttonLoading}
                            htmlType='submit'
                            style={{ width: "100%", height: "35px", marginTop: 20 }}
                            disabled={alertDeudas}
                        >
                            {buttonLoading ? 'Enviando...' : 'Enviar'}
                        </Button>
                    </Form>
                </div>


                <div ref={refResult} style={{ display: resultError, padding: 50 }}>
                    <Card style={{ width: '80%', margin: 'auto' }}>
                        <Row>
                            <Col span={24}>
                                <h1 style={{ textAlign: 'start' }}>Lo sentimos</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{ fontSize: 16 }}>
                                    Lamentamos informarte que, por el momento, no cumples con los requisitos necesarios para acogerte a la Ley de Segunda Oportunidad.
                                </p>
                                <p style={{ fontSize: 16 }}>
                                    Uno de los criterios fundamentales es encontrarte en una situación de insolvencia, ya sea actual o inminente.
                                </p>
                                <p style={{ fontSize: 16 }}>
                                    ¿A qué nos referimos? Si la insolvencia es "actual", significa que ya no puedes hacer frente a tus deudas. Si es "inminente", implica que en los próximos 3 meses no podrás asumir esos pagos.
                                </p>
                                <p style={{ fontSize: 16 }}>
                                    Gracias por confiar en nosotros y esperamos poder ayudarte en un futuro.
                                </p>
                            </Col>
                        </Row>
                    </Card>
                </div>

                {step === 2 && (
                    <div ref={refResult} style={{ display: result, padding: 50 }}>
                        <div>
                            <Result
                                icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                                style={{ padding: '1.5rem', paddingTop: '3rem' }}
                                status="success"
                                title="¡Gracias!"
                                subTitle={<span dangerouslySetInnerHTML={{ __html: subtitleText }} />}
                                extra={[
                                    <Button type="primary" key="console" target='_blank' href='https://meetings-eu1.hubspot.com/francisco-cano-fernandez'>
                                        Haz clic para concertar una cita
                                    </Button>
                                ]}
                            />
                        </div>
                    </div>
                )}
            </ConfigProvider >
        </>
    );
};

export default SegundaOportunidad;