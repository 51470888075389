const banco = [
    "ABANCA VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, S.A",
    "ALLIANZ POPULAR VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A., SOCIEDAD UNIPERSONAL",
    "BANKIA MAPFRE VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "BANKINTER SEGUROS DE VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "BANSABADELL VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "BBVASEGUROS, S.A., DE SEGUROS Y REASEGUROS",
    "CAIXA ENGINYERS VIDA-CAJA INGENIEROS VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "CAJA GRANADA VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA",
    "CAJAMAR SEGUROS GENERALES, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "CAJAMAR VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "CAJAMURCIA VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, S.A.",
    "CCM VIDA Y PENSIONES DE SEGUROS Y REASEGUROS, SOCIEDAD ANONIMA",
    "KUTXABANK ASEGURADORA COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "KUTXABANK VIDA Y PENSIONES COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "LIBERBANK VIDA Y PENSIONES, SEGUROS Y REASEGUROS, S.A.",
    "MARCH VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUR OS",
    "MEDITERRANEO VIDA, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "NATIONALE-NEDERLANDEN VIDA, SEGUROS S.A / ING BANK NV-Operador de Banca Seguros",
    "RGA RURAL VIDA, S.A. DE SEGUROS Y REASEGUROS",
    "SA NOSTRA COMPAÑÍA DE SEGUROS DE VIDA, S.A.",
    "SANTANDER MAPFRE SEGUROS Y REASEGUROS, S.A.",
    "SANTANDER SEGUROS Y REASEGUROS, COMPAÑIA ASEGURADORA, S.A.",
    "SANTANDER VIDA SEGUROS Y REASEGUROS, S.A.",
    "SEGURCAIXA ADESLAS, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "SEGUROS CATALANA OCCIDENTE, SOCIEDAD ANONIMA DE SEGUROS Y REASEGUROS",
    "UNICORP VIDA, COMPAÑIA DE SEGUROS Y REASEGUROS, S.A.",
    "UNION DEL DUERO, COMPAÑIA DE SEGUROS DE VIDA, S.A.",
    "VIDACAIXA, SOCIEDAD ANÓNIMA DE SEGUROS Y REASEGUROS",
    "METLIFE EUROPE D.A.C.",
    "PREVISORA AGRO-INDUSTRIAL, MUTUALITAT DE PREVISIÓ SOCIAL A PRIMA FIXA",
    "SEGUROS DE VIDA Y PENSIONES ANTARES, S.A."
];

export default banco;