import React from 'react';
import Home from './pages/Home';
import FormPrimaUnica from './forms/FormPrimaUnica';
import PaginaPrincipal from './forms/PaginaPrincipal/PaginaPrincipal';
import PaginaPrincipalHefame from './forms/PaginaPrincipalHefame/PaginaPrincipalHefame';
import FormBlogCancelaSeguro from './forms/FormBlogCancelaSeguro';
import FormHefame from './forms/FormHefame';
import FormIRPH from './forms/FormIRPH';
import FormGastosHipotecarios from './forms/FormGastosHipotecarios';
import FormRevolving from './forms/FormRevolving';
import FormCalcularHipoteca from './forms/CalcularHipoteca/FormCalcularHipoteca'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FormCalcularEuribor from './forms/CalcularEuribor/FormCalcularEuribor';
import SegundaOportunidad from './forms/SegundaOportunidad/SegundaOportunidad';
import FormPaginaPrincipalSingularParte1 from './forms/PaginaPrincipalSingular/PaginaPrincipalSingular';
import FormComplementoPaternidad from './forms/FormComplementoPaternidad';
import FormComplementoPaternidadPago from './forms/FormComplementoPaternidadPago';
import LandingCaser from './forms/LandingCaser/LandingCaser';
import FormLeadExpat from './forms/FormLeadExpat';
import CaserFormSoloBanco from './forms/CaserFormSoloBanco/CaserFormSoloBanco';
import FormBlogPrimaUnica from './forms/FormBlogPrimaUnica';
import Fintonic from './forms/Fintonic/Fintonic';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/form/ef2c9b84648a4032a42d069231660f11" element={<FormPrimaUnica />} />
        <Route exact path='/form/c5a9cf6eb60e41cdace860bfcc8ff258' element={<PaginaPrincipal />} />
        <Route exact path='/form/5090b44271854cdc8cb1e7995ec386a6' element={<FormBlogCancelaSeguro />} />
        <Route exact path='/form/55bc18b911c041f6bf214e52d3b935fe' element={<FormHefame />} />
        <Route exact path='/form/a763b12099ec4e29a241ce78cdd72d01' element={<FormIRPH />} />
        <Route exact path='/form/2448cd78773111eeb9620242ac120002' element={<FormGastosHipotecarios />} />
        <Route exact path='/form/93a31456e39b41ce8f03259e711ee93c' element={<FormRevolving />} />
        <Route exact path='/form/2b9cb43ceead41e5a4124f5d94e6b128' element={<FormCalcularHipoteca />} />
        <Route exact path='/form/7cbaf8eb78e543cd80f26f60ab2dc8e7' element={<PaginaPrincipalHefame />} />
        <Route exact path='/form/10af26b8821211eeb9620242ac120002' element={<FormCalcularEuribor/>} />
        <Route exact path='/form/88cc03dc87c111eeb9d10242ac120002' element={<SegundaOportunidad/>} />
        <Route exact path='/form/2e36bc982d1e44fdbc8177795a676f1d' element={<FormPaginaPrincipalSingularParte1/>} />
        <Route exact path='/form/c24d26941eba4b3691af3ebd341415e1' element={<FormComplementoPaternidad/>} />
        <Route exact path='/form/b9109d8a3a0b433e872dda5b499d3c93' element={<FormComplementoPaternidadPago/>} />
        <Route exact path='/form/3b0d6d8a1d9e4e8f8e0a8f8e4b8f8e4b' element={<LandingCaser/>} />
        <Route exact path='/form/c54b2eb86f4b48fba3c918c62a9bdd17' element={<FormLeadExpat/>} />
        <Route exact path='/form/788401f833524bf7b7ac7ee49d0ed767' element={<CaserFormSoloBanco/>} />
        <Route exact path='/form/08b2a81c860040688843cb71238519a0' element={<FormBlogPrimaUnica/>} />
        <Route exact path='/form/5dbaa25e140546f18e2dd73edc1158e0' element={<Fintonic/>} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;