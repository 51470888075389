import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Checkbox, ConfigProvider, Result, InputNumber, Select, DatePicker, notification, Divider, Alert, Radio } from 'antd';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { CheckCircleFilled, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import aseguradoras from '../data/aseguradora';
import changeHeight from '../data/changeHeight';
import { getOverflowOptions } from 'antd/es/_util/placements';

const FormComplementoPaternidadPago = () => {
    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [alertAnoJubilacion, setAlertAnoJubilacion] = useState(false);
    const [alertHijos, setAlertHijos] = useState(false);
    const [mostrarPreguntaAdicional, setMostrarPreguntaAdicional] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [alertGenero, setAlertGenero] = useState(false);


    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: searchParams.get("parentUrl") });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = (values) => {
        setbuttonLoading(true);
        axios.post(`${process.env.REACT_APP_URL_PRIMA_UNICA}`, values)
            .then(function (response) {
                if (response.data.message === "OK") {
                    setbuttonLoading(false);
                    setSuccess(true);
                    setResult("block");
                    changeHeight(heightResult);
                }
            })
            .catch(function () {
                openNotification('bottom');
                setbuttonLoading(false);
            });
    };

    const onChangeTipoJubilacion = (value) => {
        if (value === 'Anticipada') {
            setMostrarPreguntaAdicional(true);
            setMostrarAlerta(false); // Resetear la alerta en caso de que el usuario cambie su selección
        } else {
            setMostrarPreguntaAdicional(false);
            setMostrarAlerta(false);
        }
    };

    const onChangePreguntaAdicional = (value) => {
        if (value === 'Voluntaria') {
            setMostrarAlerta(true);
        } else {
            setMostrarAlerta(false);
        }
    };



    const disabledDateBeforeToday = (current) => {
        // Can not select days before today, today yes
        return current && current > dayjs().endOf('day');
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const onChangeAnoJubilacion = (value) => {
        if (value === "otro") {
            setAlertAnoJubilacion(true);
        } else {
            setAlertAnoJubilacion(false);
        }
    }

    const onChangeHijos = (value) => {
        if (value === "0" || value === "1") {
            setAlertHijos(true);
        } else {
            setAlertHijos(false);
        }
    }

    const onChangeGenero = (value) => {
        if (value !== 'Hombre') {
            setAlertGenero(true);
        } else {
            setAlertGenero(false);
        }
    }


    const subtitleText = "Tu información ha sido enviada correctamente.<br/>En breve nos pondremos en contacto contigo, pero recuerda que puedes concertar una cita haciendo clic en el siguiente botón.";

    return (

        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Form
                        form={form}
                        style={{ padding: '1rem' }}
                        hidden={success}
                        name="basic"
                        initialValues={{ remember: true }}
                        layout='vertical'
                        onFinish={onFinish}
                    >
                        <Form.Item
                            hidden={true}
                            label="URL"
                            name="url"
                        >
                            <Input type='text' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="FB"
                            name="fbp">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            hidden={true}
                            label="Hubspotutk"
                            name="hubspotutk">
                            <Input type='' />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuál es tu género?</p>}
                            name="genero"
                            rules={[{ required: true, message: 'Por favor, escoge una opción' }]}
                        >
                            <Radio.Group 
                                showSearch 
                                placeholder='Escoge una opción' 
                                disabled={buttonLoading} 
                                onChange={(e) => onChangeGenero(e.target.value)}>
                                <Radio value="Hombre">Hombre</Radio>
                                <Radio value="Mujer">Mujer</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <div style={{ display: `${alertGenero ? "block" : "none"}` }}>
                            <Alert style={{ marginTop: "3%", marginBottom: "3%" }} message="Lamentablemente, para continuar el reclamante debe ser hombre." type="error" />
                        </div>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿En qué año te jubilaste?</p>}
                            name="ano_jubilacion"
                            rules={[{ required: true, message: 'Por favor, escoge un año' }]}
                        >
                            <Select showSearch placeholder='Escoge un año' disabled={buttonLoading} onChange={onChangeAnoJubilacion}>
                                <Select.Option key="2016" value="2016">2016</Select.Option>
                                <Select.Option key="2017" value="2017">2017</Select.Option>
                                <Select.Option key="2018" value="2018">2018</Select.Option>
                                <Select.Option key="2019" value="2019">2019</Select.Option>
                                <Select.Option key="2020" value="2020">2020</Select.Option>
                                <Select.Option key="2021 (antes del 3 de febrero)" value="2021 (antes del 3 de febrero)">2021 (antes del 3 de febrero)</Select.Option>
                                <Select.Option key="otro" value="otro">Otra fecha distinta</Select.Option>
                            </Select>
                        </Form.Item>

                        <div style={{ display: `${alertAnoJubilacion ? "block" : "none"}` }}>
                            <Alert style={{ marginTop: "3%", marginBottom: "3%" }} message="Si no te jubilaste entre el 1 de enero de 2016 y el 3 de febrero de 2021, lamentablemente, no eres elegible para reclamar el complemento de paternidad." type="error" />
                        </div>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Cuántos hijos tienes?</p>}
                            name="hijos"
                            rules={[{ required: true, message: 'Por favor, escoge una opción' }]}
                        >
                            <Select showSearch placeholder='Escoge una cifra' disabled={buttonLoading} onChange={onChangeHijos}>
                                <Select.Option key="0" value="0">0</Select.Option>
                                <Select.Option key="1" value="1">1</Select.Option>
                                <Select.Option key="2" value="2">2</Select.Option>
                                <Select.Option key="3" value="3">3</Select.Option>
                                <Select.Option key="4 o más" value="4 o más">4 o más</Select.Option>
                            </Select>
                        </Form.Item>

                        <div style={{ display: `${alertHijos ? "block" : "none"}` }}>
                            <Alert style={{ marginTop: "3%", marginBottom: "3%" }} message="Lamentablemente, para reclamar el complemento de paternidad, es necesario tener al menos dos hijos." type="error" />
                        </div>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Qué tipo de jubilación obtuviste?</p>}
                            name="tipo_jubilacion"
                            rules={[{ required: true, message: 'Por favor, escoge una opción' }]}
                        >
                            <Select showSearch placeholder='Escoge una opción' disabled={buttonLoading} onChange={onChangeTipoJubilacion}>
                                <Select.Option key="Ordinaria" value="Ordinaria">Jubilación ordinaria</Select.Option>
                                <Select.Option key="Anticipada" value="Anticipada">Jubilación anticipada</Select.Option>
                            </Select>
                        </Form.Item>

                        {mostrarPreguntaAdicional && (
                            <Form.Item
                                style={{ marginBottom: "0.6rem" }}
                                label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Fue voluntaria o forzosa?</p>}
                                name="tipo_jubilacion_anticipada"
                                rules={[{ required: true, message: 'Por favor, escoge una opción' }]}
                                tooltip={{ title: 'Consulta la sección "Resolvemos todas tus dudas" debajo del formulario para más información.', icon: <InfoCircleOutlined /> }}
                            >
                                <Radio.Group onChange={(e) => onChangePreguntaAdicional(e.target.value)} disabled={buttonLoading}>
                                    <Radio value="Voluntaria">Voluntaria</Radio>
                                    <Radio value="Forzosa">Forzosa</Radio>
                                </Radio.Group>
                            </Form.Item>
                        )}

                        <div style={{ display: `${mostrarAlerta ? "block" : "none"}` }}>
                            <Alert style={{ marginTop: "3%", marginBottom: "3%" }} message="Lamentablemente no llevamos casos de jubilaciones anticipadas voluntarias" type="error" />
                        </div>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde te podemos enviar un email?</p>}
                            name="email"
                            rules={[{ required: true, message: 'Por favor, ingresa tu email' }]}
                        >
                            <Input placeholder='tunombre@email.com' type='email' disabled={buttonLoading} />
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "0.6rem" }}
                            label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>¿Dónde podemos llamarte?</p>}
                            name="tel"
                            rules={[{ required: true, message: 'Por favor, ingresa tu teléfono' }]}
                        >
                            <Input placeholder='919 99 31 31' type='tel' disabled={buttonLoading} maxLength={9} minLength={9} />
                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                            <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="whatsapp" initialValue={false}>
                            <Checkbox style={{ fontSize: "15px" }} disabled={buttonLoading} >Acepto recibir asesoramiento vía WhatsApp</Checkbox>
                        </Form.Item>

                        <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }} disabled={alertAnoJubilacion || alertHijos || mostrarAlerta || alertGenero}>
                            {buttonLoading ? 'Enviando...' : 'Enviar'}
                        </Button>

                    </Form>
                </div>

                <div ref={refResult}>
                    <div style={{ display: `${result}` }}>
                        <Result
                            icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                            style={{ padding: '1.5rem', paddingTop: '3rem' }}
                            status="success"
                            title="¡Gracias!"
                            subTitle={<span dangerouslySetInnerHTML={{ __html: subtitleText }} />}
                            extra={[
                                <Button type="primary" key="console" target='_blank' href='https://meetings-eu1.hubspot.com/santiago-gatti'>
                                    Haz clic para concertar una cita
                                </Button>
                            ]}
                        />
                    </div>
                </div>
            </ConfigProvider >

        </>
    );
}

export default FormComplementoPaternidadPago;