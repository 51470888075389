import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Checkbox, ConfigProvider, Select, notification, Card, Result } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import changeHeight from '../data/changeHeight';
//Imports ajenos a el diseño
import axios from 'axios';
import aseguradoras from '../data/aseguradora';

const FormBlogCancelaSeguro = () => {

    const [buttonLoading, setbuttonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [urlResponse, seturlResponse] = useState("");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [showBirthday, setShowBirthday] = useState(false);
    const [priceBasic, setPriceBasic] = useState(null);
    const [pricePremium, setPricePremium] = useState(null);

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: decodeURIComponent(searchParams.get("parentUrl")) });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === refForm.current) {
              changeHeight(entry.target.clientHeight);
              break;
            }
          }
        });
    
        resizeObserver.observe(refForm.current);
    
        return () => {
          resizeObserver.unobserve(refForm.current);
        };
      }, []);

    const onRamoChange = (value) => {
        setShowBirthday(value === 'Vida');
        if (value !== 'Vida') {
            form.setFieldValue('birthday', undefined);
        }
    };

    const validateAge = (_, value) => {
        if (!value) return Promise.reject('Por favor, introduce tu fecha de nacimiento');
        
        const birthDate = new Date(value);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 18) {
            return Promise.reject('Debes ser mayor de edad');
        }
        
        return Promise.resolve();
    };

    const onFinish = async (values) => {
        setbuttonLoading(true);
        try {
            // Primera petición (existente)
            const response = await axios.post(`${process.env.REACT_APP_URL_BLOG_CANCELAR}`, values);
            seturlResponse(response.data.carta_url);

            // Si es Vida, hacer la segunda petición
            if (values.ramo === 'Vida') {
                const vidaResponse = await axios.post('https://hook.eu1.make.com/ia28fg17te3oxl276l3sxf0t1crcic1m', {
                    email: values.email,
                    birthday: values.birthday,
                    capital_to_ensure: 50000,
                    from: 'blog_cancelar_seguro'
                });
                
                setPriceBasic(vidaResponse.data.priceBasic);
                setPricePremium(vidaResponse.data.pricePremium);
            }

            setbuttonLoading(false);
            setSuccess(true);
            setResult("block");
            changeHeight(heightResult);
        } catch (error) {
            openNotification('bottom');
            setbuttonLoading(false);
        }
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description:
                'Ha ocurrido un error inesparado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Card
                        title="Formulario para recibir tu carta de anulación"
                        style={{ width: '100%', backgroundColor: "#f0f2f5" }}
                        headStyle={
                            {
                                backgroundColor: primaryColor,
                                color: "white"
                            }
                        }
                    >
                        <div>
                            <Form
                                form={form}
                                style={{ padding: '1.5rem' }}
                                hidden={success}
                                name="basic"
                                initialValues={{ remember: true }}
                                layout='vertical'
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    hidden={true}
                                    label="URL"
                                    name="url"
                                >
                                    <Input type='text' />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre y apellidos del asegurado</p>}
                                    name="name"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, ingresa tu nombre completo' }]}
                                >
                                    <Input placeholder='Nombre y apellidos' type='text' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Número de DNI del asegurado</p>}
                                    name="dni"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduce el DNI asegurado' }]}
                                >
                                    <Input placeholder='12345678A' type='text' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Nombre de la aseguradora</p>}
                                    name="aseguradora"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, escoge tu banco o aseguradora' }]}
                                >
                                    <Select showSearch placeholder='Escoge tu banco o aseguradora' disabled={buttonLoading}>
                                        {aseguradoras.map((aseguradora) => (
                                            <Select.Option key={aseguradora} value={aseguradora}>{aseguradora}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Ramo</p>}
                                    name="ramo"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, escoge el ramo de tu seguro' }]}
                                >
                                    <Select 
                                        showSearch 
                                        placeholder='Escoge tu banco o aseguradora' 
                                        disabled={buttonLoading}
                                        onChange={onRamoChange}
                                    >
                                        <Select.Option key='Auto' value='Auto'>Coche/Moto</Select.Option>
                                        <Select.Option key='Hogar' value='Hogar'>Hogar</Select.Option>
                                        <Select.Option key='Salud' value='Salud'>Salud</Select.Option>
                                        <Select.Option key='Vida' value='Vida'>Vida</Select.Option>
                                        <Select.Option key='Accidentes' value='Accidentes'>Accidentes</Select.Option>
                                        <Select.Option key='Otros' value='Otros'>Otros</Select.Option>
                                    </Select>
                                </Form.Item>

                                {showBirthday && (
                                    <Form.Item
                                        label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Fecha de nacimiento</p>}
                                        name="birthday"
                                        style={{ marginBottom: "0.6rem" }}
                                        rules={[
                                            { required: true, message: '' },
                                            { validator: validateAge }
                                        ]}
                                    >
                                        <Input type="date" disabled={buttonLoading} />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Referencia / Número de póliza</p>}
                                    name="poliza"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduzca su referencia o número de póliza' }]}
                                >
                                    <Input type='text' disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Teléfono</p>}
                                    name="telefono"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduzca su teléfono' }]}
                                >
                                    <Input type='tel' maxLength={9} disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>Email</p>}
                                    name="email"
                                    style={{ marginBottom: "0.6rem" }}
                                    rules={[{ required: true, message: 'Por favor, introduzca su correo electrónico' }]}
                                >
                                    <Input type='text' disabled={buttonLoading} />
                                </Form.Item>


                                <Form.Item style={{marginBottom: "0.6rem", marginBottom: "-6px"}} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Por favor, acepta los términos y condiciones")); } } }]}>
                                    <Checkbox disabled={buttonLoading} >Acepto los <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>términos y condiciones</Link></Checkbox>
                                </Form.Item>

                                <Form.Item style={{marginBottom: "0.6rem"}} valuePropName="checked" name="interested_price" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Este campo es obligatorio")); } } }]}>
                                    <Checkbox disabled={buttonLoading} >Acepto que me ofrezcan un mejor precio (<Link target='_blank' to='https://legal-public-docs-cleverea.s3-eu-west-1.amazonaws.com/Privacy_policy/Cleverea_Privacy_Policy_ES.pdf'>términos y condiciones</Link>)</Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <Button type='primary' loading={buttonLoading} htmlType="submit">
                                        {buttonLoading ? 'Enviando...' : 'Recibir la carta para cancelar mi seguro'}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div ref={refResult}>
                            <div style={{ display: `${result}`, padding: '1.5rem' }}>
                                <Result
                                    icon={<CheckCircleFilled style={{ color: primaryColor }} />}
                                    style={{ display: `${result}`, padding: '1.5rem', paddingTop: '3rem' }}
                                    status="success"
                                    title="¡Hecho! Revisa tu email"
                                    subTitle={
                                        <>
                                            <p>{`Muchas gracias ${form.getFieldValue('name')}! Hemos enviado a tu email la carta para que solicites la cancelación de tu seguro.`}</p>
                                            {priceBasic && pricePremium && (
                                                <div style={{
                                                    marginTop: '30px',
                                                    padding: '20px',
                                                    border: `2px solid ${primaryColor}`,
                                                    borderRadius: '8px',
                                                    backgroundColor: '#f8f9fa'
                                                }}>
                                                    <h3 style={{
                                                        color: primaryColor,
                                                        textAlign: 'center',
                                                        marginBottom: '20px',
                                                        fontSize: '1.5em'
                                                    }}>
                                                        ¡Espera! Tenemos esta oferta para que sigas cubierto por tan solo...
                                                    </h3>
                                                    
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        gap: '20px',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        {/* Plan Básico */}
                                                        <Card style={{
                                                            width: 300,
                                                            textAlign: 'center',
                                                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                                                        }}>
                                                            <h4>Seguro de fallecimiento</h4>
                                                            <div style={{
                                                                fontSize: '2em',
                                                                color: primaryColor,
                                                                margin: '15px 0'
                                                            }}>
                                                                {priceBasic}€
                                                                <span style={{
                                                                    fontSize: '0.4em',
                                                                    color: '#666'
                                                                }}>/año</span>
                                                            </div>
                                                            <ul style={{
                                                                listStyle: 'none',
                                                                padding: 0,
                                                                margin: '20px 0',
                                                                textAlign: 'left'
                                                            }}>
                                                                <li>✓ Cobertura por fallecimiento</li>
                                                                <li>✓ Capital asegurado: 50.000€</li>
                                                                <li>✓ Sin período de carencia</li>
                                                            </ul>
                                                            <Button 
                                                                type="primary"
                                                                href={`https://wa.me/34960130494?text=Hola,%20me%20interesa%20el%20seguro%20Fall%20por%20${priceBasic}€%20al%20año`}
                                                                target="_blank"
                                                                style={{width: '100%'}}
                                                            >
                                                                Contactar por WhatsApp
                                                            </Button>
                                                            <Button 
                                                                type="primary"
                                                                href={`https://meetings-eu1.hubspot.com/santiago-gatti`}
                                                                target="_blank"
                                                                style={{width: '100%', marginTop: '10px'}}
                                                            >
                                                                Concertar cita
                                                            </Button>
                                                        </Card>

                                                        {/* Plan Premium */}
                                                        <Card style={{
                                                            width: 300,
                                                            textAlign: 'center',
                                                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                                            border: `2px solid ${primaryColor}`
                                                        }}>
                                                            <h4>Seguro Fall + Invalidez</h4>
                                                            <div style={{
                                                                fontSize: '2em',
                                                                color: primaryColor,
                                                                margin: '15px 0'
                                                            }}>
                                                                {pricePremium}€
                                                                <span style={{
                                                                    fontSize: '0.4em',
                                                                    color: '#666'
                                                                }}>/año</span>
                                                            </div>
                                                            <ul style={{
                                                                listStyle: 'none',
                                                                padding: 0,
                                                                margin: '20px 0',
                                                                textAlign: 'left'
                                                            }}>
                                                                <li>✓ Cobertura por fallecimiento</li>
                                                                <li>✓ Invalidez permanente absoluta</li>
                                                                <li>✓ Capital asegurado: 50.000€</li>
                                                                <li>✓ Sin período de carencia</li>
                                                            </ul>
                                                            <Button 
                                                                type="primary"
                                                                href={`https://wa.me/34960130494?text=Hola,%20me%20interesa%20el%20seguro%20Fall%2BIPA%20por%20${pricePremium}€%20al%20año`}
                                                                target="_blank"
                                                                style={{width: '100%'}}
                                                            >
                                                                Contactar por WhatsApp
                                                            </Button>
                                                            <Button 
                                                                type="primary"
                                                                href={`https://meetings-eu1.hubspot.com/santiago-gatti`}
                                                                target="_blank"
                                                                style={{width: '100%'}}
                                                            >
                                                                Concertar cita
                                                            </Button>
                                                        </Card>
                                                    </div>
                                                    
                                                    <div style={{
                                                        textAlign: 'center',
                                                        marginTop: '20px',
                                                        color: '#666'
                                                    }}>
                                                        <p>¿Prefieres que te llamemos? Llámanos al <a href="tel:919993131">919 993 131</a></p>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </ConfigProvider>
        </>
    );
}

export default FormBlogCancelaSeguro;
